import { Text, Grid, Spinner } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { authStore } from "../store/AuthStore";
import { activityStore } from "../store/ActivityStore";
import { QrReader } from 'react-qr-reader';
import { Page } from "../components/Page";

export const ScanQR: React.FC = observer(() => {
  const [error, setError] = useState('')
  useEffect(() => {
    if (!authStore.user?.id) {
      console.log('get user?');
      const u = authStore.getUser();
      console.log(u);
    }
  }, []);
  return (
    <Page>
      <Grid px="1em">
        <Text fontWeight="bold" fontSize="22px" textAlign="center">
          Scan In-Store promotions
        </Text>
          { activityStore.isLoading && <Spinner mt="2em" placeSelf="center" w="100px" h="100px" /> }
          <QrReader
            videoStyle={activityStore.isLoading && { display: 'none' }}
            constraints={{ facingMode: "environment" }}
            onResult={async (result) => {
              if (!!result) {
                const [store, store_promotion] = result['text'].split('-');
                if (store && store_promotion && authStore.user) {
                  await activityStore.createEvent({
                    customer: authStore.user.id,
                    action: 'qr',
                    data: {
                      "store": store,
                      "store_promotion": store_promotion
                    }
                  })
                  const url = new URL(document.location.href);
                  url.pathname = '/';
                  url.searchParams.set('register', 'true');
                  document.location.href = url.href;
                } else {
                  setError('Bad QR code, Please scan again')
                }
              }
          }}
        />
        <Text color="red.500">{error}</Text>
      </Grid>
    </Page>
  )
})