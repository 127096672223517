import {
  createBrowserRouter,
} from "react-router-dom";
import { Login } from './pages/Login';
import { SignUp } from './pages/SignUp';
import { Home } from "./pages/Home";
import { ActivityHistory } from "./pages/ActivityHistory";
import { Rewards } from "./pages/Rewards";
import { ScanQR } from "./pages/ScanQR";
import { QRDisplay } from "./pages/QrDisplayer";
import { InStorePromo } from "./pages/InStorePromo";
import { UploadReceipt } from './pages/UploadReceipt';

const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: '/activity-history',
      element: <ActivityHistory />,
    },
    {
      path: '/rewards',
      element: <Rewards />,
    },
    {
      path: '/scan',
      element: <ScanQR />,
    },
    {
      path: '/in_store_promotion',
      element: <QRDisplay />
    },
    {
      path: '/validate_store_promotion',
      element: <InStorePromo />
    },
    {
      path: 'scan-receipt',
      element: <UploadReceipt />
    }
  ]);

export { router }