import { Grid, useToast } from "@chakra-ui/react";
import React from "react";
import { HomeExtraPoints } from "../components/HomeExtraPoints";
import { Page } from "../components/Page";
import { TierTracker } from "../components/TierTracker";

export const Home: React.FC = () => {
  const toast = useToast();
  const query = document.location.search;
  if (query.includes('register=true')) {
    setTimeout(() => {
      toast({ status: 'success', title: `Successfully registered promotion scan`, position: 'top-right' });
    }, 300)
  }
  return (
    <Page>
      <Grid templateRows="1fr 1f 1fr">
        <TierTracker />
        <HomeExtraPoints />
      </Grid>
    </Page>
  )
}