import { Box, Grid, Text } from "@chakra-ui/react";
import QRCode from "react-qr-code";
import React, { useEffect, useState } from "react";
import { challengesStore } from "../store/ChallengesStore";

const data = {
  title: 'loyalty members ',
  code: 'dedhamMA-A'
}

type ChallengeEntity = {
  cf_stores?: string;
  segment: {
    name: string
  }[];
  name: { en: string },
  url: string
}

export const QRDisplay: React.FC = () => {
  const [challenges, setChallenges] = useState<ChallengeEntity[] | undefined>(undefined)
  useEffect(() => {
    const run = async () => {
      const e = await challengesStore.getAllEntityChallenges();
      setChallenges(e)
    }
    run();
  }, []);
  const params = new URL(document.location.href).searchParams;
  const store = params.get("store");
  const withStores = challenges?.filter((c) => !!c.cf_stores);
  console.log(withStores);
  const found = withStores?.find((c) => c.cf_stores === store)
  if (found) data.title =`loyalty members  ${found.name.en}`;
  let code = '';
  if (found) code = found.url;
  return (
    <Grid
      justifyItems="center"
      gap="2em"
      bg="brand.500"
      h="100vh"
      placeContent="center"
    >
      <Text textAlign="center" my="1.5em" fontWeight="bold" maxW="30ch" fontSize="32px" color="brand.700">
        {data.title.toUpperCase()}
      </Text>
      {code && <QRCode
        value={code}
        bgColor="transparent"
        fgColor="#ffffff"
        size={300}
      />}
      {
        !found && 
      <Text textAlign="center" my="1.5em" fontWeight="bold" maxW="30ch" fontSize="32px" color="brand.700">
        Oops, can't find any promo for this store
      </Text>
      }
      <Box justifyContent="center">
      </Box>
      <Text textAlign="center" color="brand.100">
        Scan QR code to Activate
      </Text>
    </Grid>
  );
}