import { makeAutoObservable } from 'mobx';

interface Activity {
  id: string;
  action: string;
  label: string;
  points: number;
  timestamp: string;
}

class ActivityStore {
  activities: Activity[] = [];
  isLoading: boolean = false;
  customerApiUrl?: string = process.env.REACT_APP_CUSTOMER_API_URL;
  constructor() {
    makeAutoObservable(this);
  }

  async getActivities(customerId: string) {
    this.isLoading = true;
    if (!this.customerApiUrl) {
      console.error('process.env.REACT_APP_CUSTOMER_API_URL is not defined and it is needed by the activity store');
      return;
    }
    const res = await fetch(`${this.customerApiUrl}/${customerId}/activities`,  { headers: { 'Accept-Language': 'en' }});
    const json = await res.json();
    if (json.data) {
      this.activities = json.data;
    }
    this.isLoading = false;
    return this.activities;
  }

  async createEvent(event: Event) {
    this.isLoading = true;
    if (!this.customerApiUrl) {
      console.error('process.env.REACT_APP_CUSTOMER_API_URL is not defined and it is needed by the activity store');
      return;
    }
    const res = await fetch(
      `${this.customerApiUrl}/event`,
      {
        method: 'POST',
        headers: { 'Accept-Language': 'en' },
        body: JSON.stringify(event)
      }
    );
    const json = await res.json();
    this.isLoading = false;
    return json
  }
}

export const activityStore = new ActivityStore();

export type Event = {
  customer: string;
  action: string;
  data: { [key: string]: string }
}