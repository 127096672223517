import React, { useRef, useState } from "react";
import { Flex, Stack, useToast, Button, Text, Input, Image, Box } from "@chakra-ui/react";
import { Page } from "../components/Page";

export const UploadReceipt: React.FC = () => {
  const toast = useToast();
  const [src, setSrc] = useState('');
  const [isLoading, setLoading] = useState(false);
  const input = useRef<any>(0);

  return (
    <Page>
      <Stack w="500px" h="100vh" m="2em" direction="column" spacing="2em">
        <Flex
          position="relative"
          alignItems="center"
          w="500px"
          maxW="100%"
          padding="25px"
          border="1px dashed blue"
          borderRadius="3px"
          transition="0.2s"
        >
          <Button
            flexShrink={0}
            border="1px solid rgba(255, 255, 255, 0.1)"
            borderRadius="3px"
            padding="8px 15px"
            mr="10px"
            fontSize="12px"
            textTransform="uppercase"
            userSelect="none"
            variant="solidSecondary"
          >
            Choose File
          </Button>
          <Text
            fontSize="small"
            fontWeight={300}
            lineHeight={1.4}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            cursor="pointer"
            userSelect="none"
          >
            or drag and drop files here
          </Text>
          <Input
            type="file"
            position="absolute"
            left={0}
            top={0}
            height="100%"
            width="100%"
            cursor="pointer"
            opacity={0}
            _focus={{
              outline: 0,
            }}
            ref={input}
            accept="image/png, image/jpeg"
            onChange={() => {
              const file = input.current?.files[0];
              if (file) {
                const src = URL.createObjectURL(file);
                setSrc(src);
              }
            }}
          />
        </Flex>
        {src ? (
          <>
            <Image
              alt="no-image"
              boxSize="500px"
              src={src}
            />
            <Button
              w="250px"
              variant="solidSecondary"
              alignSelf="center"
              isLoading={isLoading}
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                    toast({
                      status: 'success',
                      title: `Successfully uploaded receipt`,
                      position: 'top',
                    });
                    setLoading(false);
                  },
                  5000
                );
              }}
              _disabled={{
                bg: 'brand.500 !important',
                color: 'brand.700',
                opacity: 0.5
              }}
            >
              Upload
            </Button>
          </>
        ) : (
          <Box h="500px" />
        )}
      </Stack>
    </Page>
  )
};
