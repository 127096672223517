import React, { useEffect, useState } from "react";
import { Page } from "../components/Page";
import { Grid, Spinner, Text } from "@chakra-ui/react";
import { activityStore } from "../store/ActivityStore";
import { authStore } from "../store/AuthStore";

export const InStorePromo: React.FC = () => {
  const params = new URL(document.location.href).searchParams;
  const store = params.get("store");
  const storePromotion = params.get("store_promotion");
  const [error, setError] = useState('')
  useEffect(() => {
    let user = authStore.user;
    if (!user) {
      user = authStore.getUser()
      if (!user) {
        localStorage.setItem('redirect_after_login', document.location.href);
        document.location.pathname = '/login';
      }
    }
    const run = async() => {
      console.log(user, store, storePromotion);
      
      if (user && store && storePromotion) {
        await activityStore.createEvent({
          customer: user.id,
          action: 'qr',
          data: {
            "store": store,
            "store_promotion": storePromotion
          }
        })
        const url = new URL(document.location.href);
        url.pathname = '/';
        url.searchParams.set('register', 'true');
        document.location.href = url.href;
      } else {
        setError('Bad Promotion code, Please scan it again')
      }
    }
    run()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Page>
      <Grid placeContent="center" minH="50vh">
        <Text fontWeight="bold" fontSize="22px" textAlign="center">
            Applying promotions
          </Text>
        {!error && <Spinner mt="2em" placeSelf="center" w="100px" h="100px" />}
        <Text color="red.500">{error}</Text>
      </Grid>
    </Page>
  )
}