import React, { useState } from 'react';
import { Button, Heading, Input, Grid, Link, Text } from '@chakra-ui/react';
import { useForm, SubmitHandler } from "react-hook-form";
import { authStore } from '../store/AuthStore';
import edmontonBanner from '../assets/edmonton-banner.jpg';

type Inputs = {
  email: string
  password: string
  confirm: string
  first_name: string
  last_name: string
}

export const SignUpForm: React.FC = () => {
  const {
    register,
    handleSubmit,
  } = useForm<Inputs>()
  const [error, setError] = useState<undefined | string>(undefined)
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (data.password !== data.confirm) {
      setError('Passwords don\'t match');
    } else if (data.email && data.password && data.first_name && data.last_name) {
      const response = await authStore.signUp(
        data.password,
        data.confirm,
        data.first_name,
        data.last_name,
        data.email
      );
      if (!response?.error) {
        const redirect = localStorage.getItem('redirect_after_login');
        if (redirect) {
          localStorage.removeItem('redirect_after_login');
          document.location.href = redirect;
        } else {
          document.location.pathname = '/';
        }
      } else {
        setError(response.error);
      }
    }
  }
  return (
    <Grid gap="1.5em" bg="brand.100" placeContent="start center" w="100%" h="100%" paddingTop="1em" alignItems="start" backgroundImage={edmontonBanner}>
      <Heading letterSpacing="-1px" color="brand.500" size="xs">Create an Account</Heading>
      <Input _placeholder={{
        color: "brand.950"
      }} variant="flushed" minW="300px" placeholder='Email*' type="email" required {...register('email')} />
      <Input _placeholder={{
        color: "brand.950"
      }} variant="flushed" minW="300px" placeholder='First Name*' type="text" required {...register('first_name')} />
      <Input _placeholder={{
        color: "brand.950"
      }} variant="flushed" minW="300px" placeholder='Last Name*' type="text" required {...register('last_name')} />
      <Input _placeholder={{
        color: "brand.950"
      }} variant="flushed" minW="300px" placeholder='Password*' type="password" required {...register('password')} />
      <Input _placeholder={{
        color: "brand.950"
      }} variant="flushed" minW="300px" placeholder='Confirm Password*' type="password" required {...register('confirm')} />
      {error && <Text color="red.600">{error}</Text>}
      <Button
        color="white"
        onClick={handleSubmit(onSubmit)}
        isLoading={authStore.isLoading}
        loadingText="Loading..."
        variant="solid"
      >
        Sign Up
      </Button>
      <Link href='/login' textAlign="center" type="reset">Sign In</Link>
    </Grid>
  )
}