import React from "react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Grid } from "@chakra-ui/react";

type PageProps = {
  children: JSX.Element;
}

export const Page: React.FC<PageProps> = ({ children }) => {
  return (
    <Grid bg="brand.700" minH="100vh" pt="100px" templateRows="auto 1fr auto">
      <Header />
      {children}
      <Footer />
    </Grid>
  );
}