import { Box, Grid, Heading, Skeleton, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { authStore } from "../store/AuthStore";
import { observer } from "mobx-react-lite";
import { challengesStore } from "../store/ChallengesStore";
import { ChallengeCard } from "./ChallengeCard";
// import { GaugeChallenge } from "./GaugeChallenge";

export const HomeExtraPoints: React.FC = observer(() => {
  const user = authStore.user;
  useEffect(() => {
    (async () => {
      if (user?.id) {
        await challengesStore.getChallenges(user.id);
      } else {
        authStore.getUser();
      }
    })();
  }, [user])
  const challenges = challengesStore.challenges;
  const [isDesktop] = useMediaQuery('(min-width: 800px)');
  return (
    <Grid gap="1em" templateRows="auto 1fr" backgroundColor="white800" padding="2em 0 2em 0">
      <Grid py="1em" justifyItems="center" color="brand.100">
        <Heading letterSpacing="-1px" size="sm">UNLOCK EXCLUSIVE PERKS</Heading>
        <Heading letterSpacing="-1px" size="xl" borderBottom="1px solid" borderColor="brand.300">LEVEL UP</Heading>
      </Grid>
      {challengesStore.isLoading && <Box bg="brand.100"> <Skeleton /> </Box>}
      {!challengesStore.isLoading && (
        <Grid
          overflow="auto"
          autoFlow={isDesktop ? 'column' : 'row'}
          gap="1em"
          pl="1em"
          pr="1em"
          pb="2em"
          style={{
            scrollbarWidth: 'thin',
          }}
        >
          {
            challenges.slice().reverse().map((ch) => {
              return (
                <ChallengeCard key={ch.id} challenge={ch} />
              )
              // return (
              //   <GaugeChallenge key={ch.id} challenge={ch} />
              // )
            })
          }
        </Grid>
    )}
    </Grid>
  )
});