import { Grid, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { authStore } from "../store/AuthStore";
import { activityStore } from '../store/ActivityStore';
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import { Page } from "../components/Page";

export const ActivityHistory: React.FC = observer(() => {
  const user = authStore.user
  const activities = activityStore.activities;
  useEffect(() => {
    (async () => {
      if (user?.id) {
        activityStore.getActivities(user.id);
      } else {
        authStore.getUser()
      }
    })()
  }, [user]);
  const [isDesktop] = useMediaQuery('(min-width: 800px)');

  return (
    <Page>
      <Grid>
        <Grid p="1em 2em" gap="3em">
          <Grid color="brand.900" justifyItems="center">
            <Heading letterSpacing="-1px" size="lg">Activity</Heading>
            <Heading letterSpacing="-1px" borderBottom="1px solid" borderColor="brand.300" size="xl">History</Heading>
          </Grid>
          <Grid maxW="500px" placeSelf="center" minW="340px" justifyContent="center" gap="1em" templateColumns="1fr 1fr">
            <Grid borderRadius="10px" p="1em" justifyItems="center" bg="brand.500" color="brand.100">
              <Heading letterSpacing="-1px" size="xl" color="brand.700">{user?.spendable}</Heading>
              <Heading letterSpacing="-1px" color="brand.700" size="sm">Loyalty points</Heading>
            </Grid>
            <Grid borderRadius="10px" p="1em" justifyItems="center" bg="brand.500" color="brand.100">
              <Heading letterSpacing="-1px" size="xl" color="brand.700">{user?.spent}</Heading>
              <Heading letterSpacing="-1px" color="brand.700" size="sm">Spent Points</Heading>
            </Grid>
          </Grid>
          <Grid w={isDesktop ? '580px' : undefined} placeSelf="center" gap="1em">
            <Grid pb="1em" borderBottom="1px solid" color="brand.900" borderColor="brand.600" templateColumns=".5fr 1fr .5fr">
              <Text>Points</Text>
              <Text>Activity</Text>
              <Text>Date</Text>
            </Grid>
            {
              activities.map((act) => {
                const value = Math.abs(act.points);
                const isPositive = act.points > 0;
                let asciiIcon = '~';
                let color = 'inherit';
                if (isPositive) {
                  asciiIcon = '↑';
                  color = 'brand.500';
                } else if (act.points !== 0) {
                  asciiIcon = '↓';
                  color = 'brand.800';
                }
                return (
                  <Grid color="brand.900"  borderRadius="4px" templateColumns=".5fr 1fr .5fr" key={act.id}>
                    <Grid templateColumns="1em 1fr">
                      <Text color={color}>{asciiIcon}</Text>
                      <Text fontWeight="bold">{value}</Text>
                    </Grid>
                    <Text >{act.label}</Text>
                    <Text>{format(new Date(act.timestamp), 'MM/dd/yy')}</Text>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
});